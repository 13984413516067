.breathing-area{
    margin-top: 100px;
    justify-content: center;
    align-items: center;
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    width: 300px;
    margin: auto;
    text-align: center
}
.breathing-bubble-area{
    height: 500px;
    width: 80px;
    /*background-color: #a3b2e74d;*/
    /*border: 3px solid #4745c1;*/
    border: 3px solid #5199af;
    border-radius: 100px;
    position: relative;
    margin: auto;
    grid-column: 1 / span 2;
}
.breathing-bubble{
    position: absolute;
    width: 100%;
    padding-top: 100%;
    border-radius: 100%;
    bottom: 0;
    line-height: 0;
}
.breathing-bubble img{
    width: 100%;
}

.breathing-text{
	margin-top: 40px;
    margin-bottom: 40px;
    font-size: 40px;
    color: #fff;
    opacity: 1;
    grid-column: 1 / span 2;
    transition: opacity 400ms;
}
.breathing-timer{
    background-color: #555;
    margin: auto;
    color: white;
    border-radius: 20px;
    padding: 10px 0px;
    width: 90px;
    font-size: 18px;
    font-weight: 600;
}
.breathing-toggle{
    width: 60px;
    cursor: pointer;
    background-color: white;
    border-radius: 100%;
    line-height: 0;
    margin: auto;
}



.fade-enter{
   opacity: 0;
}
.fade-exit{
   opacity: 1;
}
.fade-enter-active{
   opacity: 1;
}
.fade-exit-active{
   opacity: 0;
}
.fade-enter-active,
.fade-exit-active{
   
}