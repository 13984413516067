.background-holder{
	position: fixed;
	width: 100%;
	height:  100%;
	pointer-events: none;
}
.background-holder .background{
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: bottom center;
  position: absolute;
}
.fishes-container{
    width: 100%;
    height: 100%;
    position: absolute;	
    
}
.fish{
	position: absolute;
	width: 100%;
	animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
  animation-timing-function: linear;
  -webkit-animation-timing-function: linear;
}
.fish.swim-right{
	animation-name: swim-right;
}
.fish.swim-left{
	animation-name: swim-left;
}
.fish-image{
	position: absolute;
	animation-name: bounce;
	animation-duration: 2s;
	animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
  animation-timing-function: linear;
  -webkit-animation-timing-function: linear;
}
.fish.swim-right .fish-image{
	right: 0;
}
.background-fish{
	position: absolute;
	width: 100%;
	animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
  animation-timing-function: linear;
  -webkit-animation-timing-function: linear;
}
.background-fish--holder{
	position: absolute;
	width: 300px;
}
.fish.swim-right .background-fish--holder{
	right: 0;
}
.background-fish .background-fish-image{
	-moz-animation: bounce 2s infinite;
	-webkit-animation: bounce 2s infinite;
	animation: bounce 2s infinite;
	position: absolute
}
.background-fish .background-fish-image:nth-child(1){
	animation-delay: 0.5s;
	-webkit-animation-delay: 0.5s;
	top: 0px;
	left: 0px;
}
.background-fish .background-fish-image:nth-child(2){
	animation-delay: 0.4s;
	-webkit-animation-delay: 0.4s;
	top: 0px;
	left: 100px;
}
.background-fish .background-fish-image:nth-child(3){
	animation-delay: 0.2s;
	-webkit-animation-delay: 0.2s;
	top: 60px;
	left: 10px;
}
.background-fish .background-fish-image:nth-child(4){
	animation-delay: 0s;
	-webkit-animation-delay: 0s;
	top: 20px;
	left: 50px;
}
.background-fish .background-fish-image:nth-child(5){
	animation-delay: 0.1s;
	-webkit-animation-delay: 0.1s;
	top: 30px;
	left: 150px;
}
.background-fish .background-fish-image:nth-child(6){
	animation-delay: 0.3s;
	-webkit-animation-delay: 0.3s;
	top: 50px;
	left: 120px;
}
@keyframes swim-right
{
	0% {transform: translateX(-350px) scaleX(-1);}
	70% {transform: translateX(100%) scaleX(-1);}
	100% {transform: translateX(100%) scaleX(-1);}
}
@keyframes swim-left
{
	0% {transform: translateX(100%);}
	70% {transform: translateX(-350px);}
	100% {transform: translateX(-350px);}
}

@keyframes bounce {
  0%, 50%, 100% {
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  25% {
    -moz-transform: translateY(-5px);
    -ms-transform: translateY(-5px);
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }
  75% {
    -moz-transform: translateY(-3px);
    -ms-transform: translateY(-3px);
    -webkit-transform: translateY(-3px);
    transform: translateY(-3px);
  }
}