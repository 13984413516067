.menu-panel-section{
	position: relative;
	z-index: 9;
}
.menu-opener.menu-opener--out-menu{
	width: 200px;
	text-align: center;
	margin-left: auto;
}
.settings-logo-container{
	padding-top: 10px;
}
.settings-logo{
	width: 50px;
	padding: 30px;
	cursor: pointer;
}
.menu-panel{
	height: 100%;
    position: fixed;
    top: 0;
    background-color: #555;
    width: 200px;
    color: white;
    right: -200px;
    transition: all 0.5s;
    display: grid;
    grid-auto-rows: 100px;
    justify-content: center;
    align-items: center;
    text-align: right;
}
.menu-panel-section-duo{
	position: relative;
}
.menu-panel-section-btn{
	padding: 30px;
	font-size: 20px;
	font-weight: 500;
}
.menu-opener--in-menu{
    position: relative;
    top: 0;
    text-align: center;
    overflow: hidden;
    height: 100px;
    width: 100px;
    margin: auto;
    padding: 0 50px;

}
.menu-opener--in-menu .settings-logo-container{
	position: absolute;
	transition: all 0.5s;
	right: 200px;
	width: 100%;
    height: 100%;
    object-fit: contain;
}
.menu-panel-section-reveal{
	position: absolute;
	top: 30px;
	right: 230px;
	width: 400px;
	border-radius: 5px;
	box-shadow:  2px 2px 8px 0px #555;
	opacity: 0;
	transition: 0s all;
	background-color: #fff;
	padding: 20px 20px 30px 20px;
	color: #555;
	/*display: none;*/
	

}
.menu-panel-section-reveal--inactive{
	pointer-events: none;
}
.menu-panel-section-reveal--active{
	opacity: 1;
	top: -20px;
	/*display: block;*/
	transition: 0.5s all;
}

.menu-panel--active{
	right: 0;
}
.menu-panel--active .menu-opener.menu-opener--in-menu .settings-logo-container{
	right: 0;
}
.menu-panel--inactive .menu-panel-section-reveal{
	display: none;
}

.timer-selector{

}
.timer-selector .slide-title{
	margin-bottom: 5px;
}
.timer-selector .slide-value{
	margin-bottom: 20px;
}

.background-selector{
	display: flex;
    text-align: center;
}
.background-selector .background-selector__single{
	margin: 0 20px;
	cursor: pointer;
}
.background-selector__single-title{
	margin-bottom: 5px;
}
.background-selector__single-img{
	width: 100px;
	height: 100px;
	background-size: cover;
}


.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 15px;
  border-radius: 5px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: .2s;
  transition: opacity .2s;
}
.slider:hover {
  opacity: 1;
}
.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #24b99f;
  cursor: pointer;
}
.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #24b99f;
  cursor: pointer;
}